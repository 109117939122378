import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

export const HubSpotFormComponent = () => {
  useEffect(() => {
    initializeHubspotForm()
  }, [])

  const initializeHubspotForm = () => {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "8283520",
        formId: "5ebba7bb-2dc0-4cce-9a2a-5a6de75c0b27",
        target: "#hubspotFormContainer",
      })
    } else {
      setTimeout(initializeHubspotForm, 500)
    }
  }

 return (
   <div>
     <Helmet>
       <script
         charset="utf-8"
         type="text/javascript"
         src="//js.hsforms.net/forms/embed/v2.js"
       ></script>
     </Helmet>
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         alignItems: "center",
         height: "100%",
       }}
     >
       <div id="hubspotFormContainer" style={{ width: "80%" }}></div>
     </div>
   </div>
 )
}
