import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"


const TwitterApiBasicGuideForMarketers = ({
    location
  }) => {
  const title =
    "マーケ担当者のためのTwitter API解説｜APIとは？Blueと違うの？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="マーケ担当者のためのTwitter API解説｜APIとは？Blueと違うの？"
          pagedesc="Twitter API有料化に伴う影響をマーケ担当者向けに解説。APIの概要、Twitter APIとTwitter Blueの違い、キャンペーン運用への影響、そしてAPI契約の必要性と手続きについてまとめました。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/220524.jpg"
          pagepath="/blog/twitter-api-basic-guide-for-marketers"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は4分で読むことができます
            </div>
            <h1>
              マーケ担当者のためのTwitter API解説｜APIとは？Blueと違うの？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年5月24日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="段ボールの上のiphone"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/220524.jpg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">Twitter API 有料化…何が変わるの？</h2>
            <p>
              2023年2月にTwitter
              APIの有料化が発表されました。この記事を執筆中の2023年5月段階でも、旧APIの停止や移行に伴うサービス凍結など、さまざまな企業、業界に影響が及んでいます。これまではAPIの詳細を理解する必要は開発者だけに求められていました。しかし今では、マーケティング担当者を含め、Twitterマーケティングを活用して集客や売上につなげたい方には必須の知識になりました。
            </p>
            <h2 id="id2">マーケターが最低限おさえるべきことだけ解説</h2>
            <p>
              だからといって、エンジニアと同じようにプログラミングの深い知識を身につけてAPIを理解するのは現実的ではないでしょう。そこで、この記事では開発知識がゼロのマーケティング担当者に向けて、Twitterマーケティングで押さえておくべき3つのポイントに絞って解説します。
            </p>
            <ol>
              <li>APIとは何か</li>
              <li>Twitter APIで何ができるか</li>
              <li>Twitterキャンペーン企画時に知っておくべきこと</li>
            </ol>
            <p>この記事は、読んでくださる担当者の方が</p>
            <p>
              「Twitter
              APIについての現状が理解でき、自社のTwitterキャンペーン開催の際の注意点がクリアになる」ことをゴールにしています。
            </p>
            <p>
              Twitter
              APIのニュースについて、詳細は不要だけど何が起きているか把握したいという方は、ぜひ最後までお読みください。
            </p>

            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>
                開発知識はないが Twitter API
                動向に興味があるマーケティング担当者
              </li>
              <li>
                インスタントウィンキャンペーン開催予定で、API利用の注意点を知っておきたい企業
              </li>
              <li>
                Twitter API
                有料化に伴う費用増加についてクライアントへ説明したい広告代理店担当者
              </li>
            </ul>
            <p>
              ※記載内容は、当記事公開時の情報に基づきます。最新の情報については、
              <a
                href="https://developer.twitter.com/en/products/twitter-api"
                target="_blank"
                rel="noreferrer"
              >
                Twitter Developer Platform, Products, Twitter APIのページ
              </a>
              をご確認ください。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitter API 有料化…何が変わるの？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    マーケターが最低限おさえるべきことだけ解説
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    １.そもそも API とは？サービス同士をつなぐもの
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        API 連携のメリット①ユーザーがさらに便利に使える
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        API 連携のメリット②サービス開発効率化、シェア拡大
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        API 連携のデメリット
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        API を扱うにはアプリケーション開発知識が必要
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    2. Twitter API で何ができる？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        Twitter API でできること
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        Twitter API のプランは無料・ベーシック・エンタープライズ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        Twitter API は Twitter Blue とはちがうの？
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    3.Twitterキャンペーンへの影響
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        プレゼントキャンペーン手動実施なら、問題なし
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        自動化、効率化、大規模キャンペーンなら、API契約が必要
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    4. 今後のTwitterキャンペーン実施で必要なこと
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        Twitter API の契約と Key 情報の取得
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        Twitter API Basicの料金
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        PARKLoT でお得にインスタントウィンを開催！
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">１.そもそも API とは？サービス同士をつなぐもの</h2>
              <p>
                APIは「Application Programming Interface
                アプリケーションプログラミングインターフェイス」の略です。簡単に言うと、異なるソフトウェアやアプリケーションが互いに情報をやり取りするための規格や方法です。外部サービスとの連携機能と考えれば、わかりやすいかと思います。
              </p>
              <h3 id="id4">API 連携のメリット①ユーザーがさらに便利に使える</h3>
              <p>
                API連携を活用すると、ユーザーは一つのサービスをより効率的に利用できます。
              </p>
              <p>
                例） 宅配便の不在通知をLINEで受け取る、
                <br />
                WebサイトにYouTube動画を埋め込む、
                <br />
                家計簿アプリで銀行口座やクレジットカード情報を一括で管理する　など
              </p>
              <p>
                気づかないうちに、API連携は生活のさまざまな場面で活用されています。
              </p>
              <p>
                API連携では、APIを公開して提供する側（サービスA）と、APIを利用する側（サービスB）との、2つのサービスが存在します。ユーザーはサービスBを利用することで、間接的にサービスAの機能を利用することができます。
              </p>

              <h3 id="id5">
                API 連携のメリット②サービス開発効率化、シェア拡大
              </h3>
              <p>API連携のメリット、はユーザー体験の向上だけではありません。</p>
              <p>
                API利用側（サービスB）にとっては、システム開発の時間を短縮したり、セキュリティ対策への労力を削減したりすることができます。
              </p>
              <p>
                また、API提供側（サービスA）にとっては、自社サービスのシェアを拡大したり、自社サービスを中心としたエコシステムを確立したりするといったメリットもあります。
              </p>

              <h3 id="id6">API 連携のデメリット</h3>
              <p>一方で、API連携にはいくつかデメリットも存在します。</p>
              <p>
                API利用側（サービスB）は、API提供サービスへの依存、API利用料の負担等が発生します。もしサービスAがAPI提供を停止すると、サービスB、そしてサービスBのユーザーにも影響が及びます。
              </p>
              <p>
                API提供側（サービスA）にとってのデメリットは、サーバー負荷の増加や、非公開情報の保護といった課題が挙げられます。
              </p>

              <h3 id="id7">API を扱うにはアプリケーション開発知識が必要</h3>
              <p>
                では、具体的にAPI連携を行うには何をすれば良いのでしょうか？概念的には、APIを操作するシステム（アプリケーション）を稼働させることが必要です。
              </p>
              <p>
                そのためには、基本的なプログラミング知識、HTTPメソッドやステータスコードの理解、データフォーマットの知識、認証の仕組みへの理解などが求められます。さらに、各サービスは独自のAPI機能や仕様を持っているため、APIのドキュメンテーションを読み解く能力も必要となります。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/rise-engagement-rate-with-retweet-or-like-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    驚きのエンゲージメント率！
                    リツイートいいねキャンペーンの魅力を徹底解説
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id8">2. Twitter API で何ができる？</h2>
              <p>
                APIを使用するとシステムが連携し、より便利な利用が可能になることを確認しました。
              </p>
              <p>
                次に、Twitterがどのような機能をAPIとして公開しているかを見てみましょう。
              </p>

              <h3 id="id9">Twitter API でできること</h3>
              <p>
                Twitterが提供しているTwitter
                APIを利用すれば、ツイート取得、ユーザーの一覧作成、リツイートのリスト作成、DM送信などが可能になります。Twitterをモバイルで利用する際に使用するTwitterアプリも、実はAPI利用の一例です。
              </p>
              <p>Twitter APIでできることの例</p>
              <ul>
                <li>特定のユーザーのツイート取得</li>
                <li>特定のユーザーのブックマーク取得</li>
                <li>リツイートユーザーの取得</li>
                <li>特定のユーザーにダイレクトメッセージ（DM）を送信</li>
                <li>ハッシュタグ付き投稿の収集</li>
                <li>リスト内のユーザーの取得　など</li>
              </ul>
              <p>
                詳しくは、
                <a
                  href="https://developer.twitter.com/en/docs/twitter-api"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter Developer Platform, Documentation, Twitter APIのページ
                </a>
                から確認できます。
              </p>
              <p>
                PARKLoTのような抽選ツールは、Twitter
                APIで取得したRTユーザーを対象に自動抽選し、再度API経由で当選ユーザーへDMを送るような仕組みとなっています。
              </p>

              <h3 id="id10">
                Twitter API のプランは無料・ベーシック・エンタープライズ
              </h3>
              <p>
                Twitter
                API有料化以前は、ある程度の制限はあるものの、基本的には自由かつ無料でAPIを使うことができていました。（一部、有料API等も存在しました。）
              </p>
              <p>新しいTwitter APIのプラン（アクセスレベル）は3通りです。</p>
              <ul>
                <li>無料　（Free）</li>
                <li>ベーシック（Basic）：月額100ドル</li>
                <li>エンタープライズ（Enterprise）：月額42,000ドル〜</li>
              </ul>
              <p>
                無料プランも継続されますが、使える機能は「Twitterログイン」と「投稿する」のみで、投稿上限は1,500件/月と、非常に限定的な利用しかできません。従来のAPIと比較して、利用可能な機能数や利用料は大幅に減少しています。
              </p>
              <p>
                3つのプランによって、同じ機能でも利用可能な量（アクセスできる回数）に差があります。例えば、同じDM送信機能でも、ベーシックとエンタープライズでは1日の送信上限に違いが生じるなどです。
              </p>
              <p>
                ※ Twitter Developer Platform,
                <a
                  href="https://developer.twitter.com/en/products/twitter-api"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter API
                </a>
                参照
              </p>

              <h3 id="id11">Twitter API は Twitter Blue とはちがうの？</h3>
              <p>
                「Twitter
                Blueに課金しているから、APIが有料化されても大丈夫でしょ？」という声もありますが、Twitter
                BlueとTwitter
                APIは異なるサービスであり、それぞれに対応が必要です。
              </p>
              <p>
                Twitter
                Blueでは、Twitterのプレミア機能が利用できます。また、アカウントに青いチェックマークが付与されます。
              </p>
              <p>
                例えるなら、Twitter
                BlueはテーマパークのVIPパスや飛行機のビジネスクラスへのアップグレードのようなものです。月額料金を払うことで、追加機能や特典を利用できるようになります。
              </p>
              <p>現在は以下のような追加機能が使えます：</p>
              <ul>
                <li>ツイートの編集</li>
                <li>広告の表示を半減</li>
                <li>長文ツイート</li>
                <li>
                  テキストの書式変更（<bold>太字</bold>と<i>斜体</i>など）
                </li>
              </ul>
              <p>
                ※ Twitterヘルプセンター、
                <a
                  href="https://help.twitter.com/ja/using-twitter/twitter-blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter Blue
                </a>
                について参照
              </p>
            </div>
            <div>
              <h2 id="id12">3. Twitterキャンペーンへの影響</h2>
              <p>
                Twitter
                APIの有料化によって、Twitterで抽選キャンペーンを実施したい企業や担当者にどのような影響があるのでしょうか。
              </p>

              <h3 id="id13">プレゼントキャンペーン手動実施なら、問題なし</h3>
              <p>
                フォローとリツイートで参加を募り、締め切り後に当選者へ手動でDMを送るキャンペーンであれば、APIの有料化による影響はありません。
              </p>

              <h3 id="id14">
                自動化、効率化、大規模キャンペーンなら、API契約が必要
              </h3>
              <p>
                その場で当たるインスタントウィンの実施や、大量の当選者への連絡が必要なキャンペーンでは、抽選ツールが導入されます。この抽選ツールこそが、外部からTwitter
                APIを利用するシステムなのです。
              </p>
              <p>
                そのため、抽選ツールを使ってキャンペーンを行う場合は、キャンペーンを開催するアカウントでAPI契約をする必要があります。あるいは、エンタープライズプランを契約した抽選ツールを利用することもできますが、この方法だと利用料が高額になる可能性があります。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id15">4. 今後のTwitterキャンペーン実施で必要なこと</h2>
              <p>
                高額なツールの導入を避け、キャンペーン開催アカウントでTwitter
                API契約をする場合、何が必要になるのでしょうか。
              </p>
              <h3 id="id16">Twitter API の契約と Key 情報の取得</h3>
              <p>
                Twitter APIの契約を行うために、まずはTwitter
                Developerアカウントの作成をします。その後、Basicプランの契約（クレジットカードの登録）とAPI
                Key情報の取得へと進みます。このAPI
                Key情報をもとに、システムからTwitter
                APIを利用することになります。（この過程には、キャンペーン開催アカウントへのログインが必須です。）
              </p>

              <h3 id="id17">Twitter API Basicの料金</h3>
              <p>
                Twitter
                APIをBasicプランで利用する場合、キャンペーンの開催費用には、開催期間に対して100ドル×月数の料金が追加されます。現時点では、契約は30日単位で行われます。キャンペーン開催に伴う必要経費として考慮する必要があります。
              </p>

              <h3 id="id18">PARKLoT でお得にインスタントウィンを開催！</h3>
              <p>
                今後もさまざまな変更が予想されるTwitterですが、日本におけるシェアはしばらく続くと見込まれます。できる範囲でマーケティングに活用し、顧客との出会いやつながり構築をしていきましょう。
              </p>
              <p>
                <a
                  href="https://www.park-lot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PARKLoT（パークロット）
                </a>
                では、インスタントウィン開催やハッシュタグ収集、UGC活用などTwitterでの販促を支援しています。企画にお困りの方は、
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料相談
                </a>
                からお気軽にお問合せください。
              </p>
              <p>API契約の段階から、サポートさせていただきます。</p>
            </div>
            <br />
            <HubSpotFormComponent />
            <br />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid"
                    target="_blank"
                    rel="noreferrer"
                  >
                    キャンペーンと好相性！UGCの活用方法や注意点について解説【Twitter/Instagram】
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-get-followers-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【今すぐ実践！】Twitter企業アカウントでフォロワーを増やす方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/secrets-of-creating-diagnostic-content-and-for-viral-twitter-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【初心者向け】診断コンテンツの作り方とキャンペーンで伸ばすコツとは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default TwitterApiBasicGuideForMarketers
